<div class=" areas">

  <section class="content sub-cat">
    <div class="box" style="    margin: 0px;">
      <div class="box-body" style="    margin: 0px;">

        <div class="seo-cell">

          <div class="row">
            <div class="col-md-12">
              <div class="tabbs">

                <ul class="nav nav-tabs">
                  <li id="productinfo" class="active"><a data-toggle="tab" href="#home" class="link-tab"> SEO Basics</a>
                  </li>


                </ul>
                <div class="tab-content">
                  <form [formGroup]="seoForm" style="padding: 0px;">
                    <div id="home" class="tab-pane fade in active">
                      <div class="row">

                        <div class="col-md-6 mb-2">
                          <label>SEO title <span style="color:red;">*</span></label>
                          <input formControlName="metaTitle" type="text" placeholder="Enter SEO title"
                            class="form-control" maxlength="250">
                          <div *ngIf="seoForm.get('metaTitle').touched && seoForm.get('metaTitle').invalid"
                            style="color: red;">
                            <span *ngIf="seoForm.get('metaTitle').errors?.required">This filed is required</span>
                          </div>
                        </div>
                        <div class="col-md-6 mb-2">
                          <label>Canonical URL</label>
                          <input formControlName="canonicalUrl" type="text" placeholder="Enter Canonical URL"
                            class="form-control" maxlength="200">
                        </div>
                        <div class="col-md-12 mb-2">
                          <label>Meta description </label>
                          <textarea formControlName="metaDescription" class="form-control"
                            placeholder="Enter Meta Description" rows="5"></textarea>
                        </div>

                        <div class="col-md-12 mb-2">
                          <label>Page Content</label>
                          <ckeditor [editor]="Editor" [config]="editorConfig" formControlName="pageContent">
                          </ckeditor>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </section>
</div>