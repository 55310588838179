import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SeoManagementService } from './seo-management.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { resourceUsage } from 'process';
import { ThemeService } from 'ng2-charts';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-seo-management',
  templateUrl: './seo-management.component.html',
  styleUrls: ['./seo-management.component.css']
})
export class SeoManagementComponent implements OnInit {
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'alphabeticalList', 'insertTable', '|', 'undo', 'redo', '|', 'style'],
  };
  public seoForm: any;
  constructor(private fb: FormBuilder,
    private seoService: SeoManagementService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router) {
    this.seoForm = fb.group({
      id: [0],
      slug: [null],
      type: [null],
      canonicalUrl: [null, [Validators.maxLength(250)]],
      metaTitle: [null, [Validators.required]],
      metaDescription: [null],
      pageContent: [null]
    });
  }

  ngOnInit(): void {

  }

  // submitSEO() {
  //   if (this.seoForm.valid) {
  //     this.spinner.show();
  //     this.seoService.manageSEOMater(this.seoForm.value).subscribe({
  //       next: (response) => {
  //         if (response.message === 'Success') {
  //         } else {
  //           this.toastr.warning(response.message);
  //         }
  //         setTimeout(() => {
  //           this.spinner.hide();
  //         }, 300);
  //       },
  //       error: (err) => {
  //         console.error('Error in ManageSEOMaster:', err);
  //         this.spinner.hide();
  //       }
  //     })

  //   } else { this.seoForm.markAllAsTouched() };
  // }

  async submitSEO() {
    if (this.seoForm.valid) {  
      try {
        const response = await this.seoService.manageSEOMater(this.seoForm.value).toPromise();
        if (response.message === 'Success') {
        } else {
          this.toastr.warning(response.message);
        }
      } catch (err) {
        console.error('Error in ManageSEOMaster:', err);
        this.toastr.error('An error occurred while managing SEO.');
      } finally {
      }
    } else {
      this.seoForm.markAllAsTouched();
    }
  }
  

  isFormValid(): boolean {
    return this.seoForm.valid;
  }

  bindSeoDetail(type: string, slug: string) {
    this.spinner.show();
    this.seoService.getSEODetails(type, slug).subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          this.seoForm.patchValue({
            id: response.result.id,
            metaDescription: response.result.metaDescription,
            metaTitle: response.result.metaTitle,
            canonicalUrl: response.result.canonicalUrl,
            pageContent:response.result.pageContent
          });
        } else {
          console.warn('SEO Details Not Found');
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 300);
      },
      error: (err) => {
        this.spinner.hide();
        console.error('Error in GetSEODeailsByTypeAndSlug:', err);
      }
    })
  }

  resetSEO() {
    this.seoForm.reset();
    this.seoForm.patchValue({
      id: 0,
    });
  }


}
