<section class="content-header padding-less">
  <h1>
    {{menuType}}

  </h1>

</section>

<div class="row">

  <div class="col-md-12">
    <div class="box box-primary">
      <!-- <div class="box-header with-border">
    
    </div> -->

      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="box-body">
          <div class=" row">

            <div class=" col-md-6">
              <div class="form-group">
                <input type="hidden" formControlName="ID" class="form-control">
                <label for="exampleInputEmail1">Title1 <span style="color: red;">*</span></label>
                <input formControlName="Title1" placeholder="Enter Title1" id="Title1" type="text" class="form-control"
                  id="input-1"
                  maxlength="300">
                <div class="validation-error" style="color: red;"
                  *ngIf="(form.get('Title1').touched && form.get('Title1').invalid && form.get('Title1').hasError('required')) ">
                  This field is required</div>
              </div>

            </div>
            <div class=" col-md-6">
              <div class="form-group">
                <label for="exampleInputEmail1">Title2</label>
                <input formControlName="Title2" placeholder="Enter Title2" id="Title2" type="text" class="form-control"
                  id="input-1">
              </div>
            </div>
          </div>
          <div class="row">
            <div class=" col-md-6">
              <div class="form-group">
                <label for="exampleInputEmail1">Title3</label>
                <input formControlName="Title3" placeholder="Enter Title3" id="Title3" type="text" class="form-control"
                  id="input-1">
              </div>

            </div>
            <div class=" col-md-6">
              <div class="form-group">

                <label class="form-label bold required">Section <span style="color: red;">*</span></label>

                <select class="form-control form-select form-select-solid fw-bolder" formControlName="Section" id="Section">
                  <option value="" disabled>Select Section</option>
                  <option *ngFor="let section of sectionOptions" [value]="section">{{ section }}</option>
                </select>

                <div class="validation-error" style="color: red;"
                  *ngIf="form.get('Section').touched && form.get('Section').invalid && form.get('Section').hasError('required')">
                  This field is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class=" col-md-6">
              <div class="form-group">
                <label for="email-b-f"> Image</label>

                <input type="file" (change)="onFileChanged($event)" accept="image/*" id="Image" class="form-control">
              </div>
            </div>
            <div class=" col-md-6">
              <div class="form-group">
                <label for="exampleInputEmail1">Author</label>
                <input formControlName="Author" placeholder="Enter Author Name" id="Title" type="text"
                  class="form-control" id="input-1" maxlength="50">
                <div class="validation-error" style="color: red;"
                  *ngIf="(form.get('Author').touched && form.get('Author').invalid && form.get('Author').hasError('required')) ">
                  This field is required</div>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="email-b-f"> Description <span style="color: red;">*</span></label>
                  <ckeditor
                  [editor]="Editor"
                  [config]="editorConfig"
                  formControlName="Description"
                ></ckeditor>
                <div class="validation-error" style="color: red;"
                  *ngIf="form.get('Description').touched && form.get('Description').invalid && form.get('Description').hasError('required')">
                  This field is required</div>
              </div>
            </div>
          </div>



        </div>







        <div class="box-footer ">
          <div class="row">
            <div class="col-md-12 nopadding">
              <button type="submit" class="btn btn-primary marginright15" id="btnsubmit">Submit</button>
              <button (click)="CancelClick()" type="button" class="btn btn-secondary" id="">Cancel</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<div class="box">
  <div class="box-header ">

    <div class="form-group col-md-4 mb-2 mt-2 padd-left">

      <select class="form-control" id="SectionT" (change)="getSectionListBySectionAndType()" style="background: #fff !important;">

        <option *ngFor="let menus of listHomePageSection" value="{{menus.id}}">{{menus.section}}
        </option>
      </select>
    </div>
    <div class="col-md-8 text-right">

      <select (change)="Filter($event.target.value,'ACTIVE-INACTIVE')" class="btn btn-primary custom-button mr-2"  name="cars" id="period"> 
        <option  value="true" >Active&nbsp;List</option>
        <option value="false">Deactive&nbsp;List</option>
        <option value="">All</option>
      </select>


      
   
    </div>
  </div>

  <div class="box-body">
    <div class="row" style="margin: 0px;">
      <div class="col-md-12">
        <div class="table-responsive">
          <table id="example1" class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>S.NO</th>
                <th>Image</th>
                <th>Title1</th>
                <th>Title2</th>
                <th>Title3</th>
                <th>Description</th>
                <th>Author </th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let Sectionlist of listHomePageSectionandType;let i=index">
                <td>{{i+1}}</td>
                <td style="width: 150px;"><img height="100px" [src]="ImageRootURL+Sectionlist.image"></td>
                <td>{{Sectionlist.title1}}</td>
                <td>{{Sectionlist.title2}}</td>
                <td>{{Sectionlist.title3}}</td>
                <td><span [innerHtml]="Sectionlist.description"></span></td>


                <td>{{Sectionlist.author}}</td>

                
                <td class="last-child ">
                  <div class="displaygap10">
                    <button class="btn btn-icon m-1 p-1 mr-2">
                      <input class="marginright10 " id="checkBoxAinA{{Sectionlist.id}}" type="checkbox" value="IsActive"
                        [checked]="Sectionlist.isActive" (change)="ActiveInactive(Sectionlist.id,Sectionlist.isActive)">
                    </button>
                    <button (click)="onEdit(Sectionlist)" class="btn btn-icon" aria-label="Product details">
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                  </div>


                </td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>

  </div>

</div>